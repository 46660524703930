/*
  PAGE TEMPLATE
  A template to display fully editable pages
*/

import React from 'react'
import { graphql } from 'gatsby'

import PostContextProvider from '@base/utils/contexts/posts-context'
import PageLayout from '@base/layout/page-layout'
import Blocks from '@base/layout/blocks'

import { getGeneralData } from '@api/general-data'
import { getAllData } from '@api/all-data'

const DynamicPage = ({ pageContext, data }) => {
  const generalData = getGeneralData(pageContext, data)
  const allData = getAllData(pageContext.globalData)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const { title, parentPage, disablePageBreadcrumbs } = data.pageData
  const contentSections = data.blocksData.contentSections

  return (
    <div className={allData?.globalData?.eventBanner?.enabled ? 'page-layout-with-banner' : ''}>
      <PageLayout
        pageContext={minifiedPageContext}
        modalData={modalData}
        breadcrumbs={{
          enableGlobalBreadcrumbs: true,
          disablePageBreadcrumbs: disablePageBreadcrumbs,
          postTitle: title,
          postParent: [parentPage],
          strings: { back: globalData.stringsActions.back, home: globalData.stringsSlugs.homeTitle }
        }}
      >
        <PostContextProvider>
          <Blocks
            pageContext={minifiedPageContext}
            globalData={{ ...globalData, brandData: allData.brandData }}
            blocks={contentSections}
          />
        </PostContextProvider>
      </PageLayout>
    </div>
  )
}
export default DynamicPage

export const query = graphql`
  query DynamicPageQuery($id: String!) {
    pageData: strapiPage(id: { eq: $id }) {
      title
      slug
      locale
      disablePageBreadcrumbs
      disableNewsletterSignup
      disableBuyGameSection
      parentPage {
        slug
        title
      }
      metadata {
        metaTitle
        metaDescription
        canonicalURL
        disablePageIndexing
        shareImage {
          url
          localFile {
            publicURL
          }
        }
        socialMediaShare {
          socialNetwork
          title
          description
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      localizations {
        data {
          attributes {
            locale
            slug
            title
            publishedAt
          }
        }
      }
    }
    blocksData: strapiPage(id: { eq: $id }) {
      contentSections {
        ...HeroBlock
        ...PostsBlock
        ...RichTextBlock
        ...CardsSliderBlock
        ...CtaBlock
        ...TogglesBlock
        ...PurchaseHeaderBlock
        ...ProductComparisonBlock
        ...DlcPreviewBlock
        ...AddOnPreviewBlock
        ...ProductsBlock
        ...LatestReleaseBlock
      }
    }
  }
`
export { Head } from '@base/layout/head'